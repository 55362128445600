import React from "react";
import useLocalStorageState from "use-local-storage-state";
import useCartItems from "../utils/oisb-cart-items";
import useCartVisibility from "../utils/oisb-cart-visibility";
import { Link } from "gatsby";
import fetch from "cross-fetch";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ImageIcon from "@mui/icons-material/Image";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import { useSnackbar } from "notistack";

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function CheckoutDialog(props) {
  const { onSubmit, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const [formState, setFormState] = React.useState({
    submitAttempted: false,
    errors: {},
    infoMsg: {},
  });

  const tryValidate = (newFormState) => {
    newFormState.errors = {};

    if (!newFormState.email) {
      newFormState.errors.email = "A valid email address is required";
    }

    if (!newFormState.name) {
      newFormState.errors.name = "Please provide your name";
    }

    if (!newFormState.address1) {
      newFormState.errors.address1 = "Please provide your address";
    }

    if (!newFormState.city) {
      newFormState.errors.city = "Please provide your city";
    }

    if (!newFormState.zip) {
      newFormState.errors.zip = "Please provide your zip code";
    }

    if (!newFormState.country) {
      newFormState.errors.country = "Please provide your country";
    }

    return !Object.keys(newFormState.errors).length;
  };

  const handleSubmitClick = () => {
    const newFormState = {
      ...formState,
      submitAttempted: true,
    };

    const isValid = tryValidate(newFormState);

    setFormState(newFormState);

    if (!isValid) {
      return;
    }

    onSubmit(formState);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const fieldId = target.id;

    const newFormState = {
      ...formState,
      [fieldId]: value,
    };

    if (fieldId === "zip") {
      const zip = (newFormState.zip || "").trim();

      if (!zip) {
        // pass
      } else if (
        zip.startsWith("98243") ||
        zip.startsWith("98245") ||
        zip.startsWith("98279") ||
        zip.startsWith("98280")
      ) {
        newFormState.infoMsg.zip = "";
      } else {
        newFormState.infoMsg.zip =
          "That doesn't look like an Orcas zip code. We may choose to prioritize sharing seeds with our more immediate neighbors. To help us prioritize please describe your plans for the seeds in the 'Request Message' box below.";
      }
    }

    if (newFormState.submitAttempted) {
      tryValidate(newFormState);
    }

    setFormState(newFormState);

    console.log(newFormState);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Checkout</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              value={formState.email || ""}
              fullWidth
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.email}
              helperText={formState.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Name"
              value={formState.name || ""}
              fullWidth
              autoComplete="given-name"
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.name}
              helperText={formState.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Address line 1"
              value={formState.address1 || ""}
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.address1}
              helperText={formState.errors.address1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label="Address line 2"
              value={formState.address2 || ""}
              fullWidth
              autoComplete="shipping address-line2"
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              value={formState.city || ""}
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.city}
              helperText={formState.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              name="state"
              label="State/Province/Region"
              value={formState.state || ""}
              fullWidth
              variant="standard"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="Zip / Postal code"
              value={formState.zip || ""}
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.zip}
              helperText={formState.errors.zip || formState.infoMsg.zip}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="country"
              name="country"
              label="Country"
              value={formState.country || ""}
              fullWidth
              autoComplete="shipping country"
              variant="standard"
              onChange={handleInputChange}
              error={!!formState.errors.country}
              helperText={formState.errors.country}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              margin="dense"
              id="request_text"
              label="Request Message"
              value={formState.request_text || ""}
              fullWidth
              variant="standard"
              rows={4}
              onChange={handleInputChange}
              helperText="Let us know your plans for the seeds. We like hearing about their new homes..."
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmitClick}>
          Request Seeds
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckoutDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const CartContainer = styled("div")(`
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 10px;
`);

const CartContents = styled("div")(`
  width: 25vw;
  padding: 10px;
`);

const Cart = () => {
  const [seedRequestToken, setSeedRequestToken] = useLocalStorageState(
    "oisb-seed-request-token",
    null
  );
  const [cartItems, setCartItems] = useCartItems();
  const [cartVisible, setCartVisible] = useCartVisibility();

  const { enqueueSnackbar } = useSnackbar();

  async function oisbApiPostRequest(url, data) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "error",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  if (!seedRequestToken && typeof window !== "undefined") {
    oisbApiPostRequest(
      `${process.env.GATSBY_API_URL}/request-seeds/get-token`,
      {}
    )
      .then((data) => {
        console.log(data);
        setSeedRequestToken(data.token);
      })
      .catch((reason) => {
        console.log("Failed to get a seed request token:", reason);

        enqueueSnackbar(
          "Failed to get a seed request token. Seed requests will not work until this succeeds. To try again, please refresh the page. If the problem persists, let us know at curator@oiseedbank.org",
          {
            key: "seed-request-token-retrieval-failure",
            variant: "warning",
            preventDuplicate: true,
          }
        );
      });
  }

  const [checkoutDialogOpen, setCheckoutDialogOpen] = React.useState(false);

  /*
  {
  "email": "testing2wa",
  "name": "symbioquinef",
  "address1": "12w teest streetssz",
  "address2": "apt 2"
  "city": "eastsounda",
  "state": "Washingtons",
  "country": "USAs",
  "zip": "98245s",
  "request_text": "Hey!s ",
  }
  */

  /*
  {
    "email": "symbioquine@gmail.com",
    "token": "AAA",
    "mailing_address": {
      "name": "Symbioquine",
      "street_address": "123 Something St.",
      "city": "Eastsound",
      "state": "WA",
      "zip": "98245"
    },
    "request_text": "Hi I would like a few seeds please!",
    "seeds_request_items": [
      {
        "primary_id": "OISB00015",
        "distribution_amount": "10g"
      }
    ]
  }
  */

  const handleSubmit = (formData) => {
    console.log(
      "Handle submit here!",
      formData,
      [formData.address1, formData.address2].filter((f) => !!f)
    );

    const streetAddress = [formData.address1, formData.address2]
      .filter((f) => !!f)
      .join("\n");

    const request = {
      email: formData.email,
      token: seedRequestToken,
      mailing_address: {
        name: formData.name,
        street_address: streetAddress,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
      },
      request_text: formData.request_text,
      seeds_request_items: cartItems.map((ci) => ({
        primary_id: ci.seed_node.primary_id,
        distribution_amount: "5g",
      })),
    };

    oisbApiPostRequest(`${process.env.GATSBY_API_URL}/request-seeds`, request)
      .then((data) => {
        console.log(data);
        setCheckoutDialogOpen(false);
      })
      .catch((reason) => {
        console.log("Failed to submit the seed request:", reason);

        enqueueSnackbar(
          "Failed to submit the seed request. To retry, please refresh the page and try submitting again. If the problem persists, let us know at curator@oiseedbank.org",
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <CartContainer>
      <Drawer
        anchor="right"
        open={cartVisible}
        onClose={() => setCartVisible(false)}
      >
        <CartContents>
          {cartItems.length === 0 && (
            <p>
              <i>
                The seeds cart is empty. Browse the{" "}
                <Link to="/seeds">Seeds</Link> to add seeds...
              </i>
            </p>
          )}

          <List component="nav" aria-label="Cart contents">
            {cartItems.map((cartItem) => (
              <ListItemLink key={cartItem.seed_node.primary_id}>
                <ListItemAvatar>
                  {(cartItem.seed_node.images[0]?.image_sharp && (
                    <Avatar
                      variant="rounded"
                      alt={cartItem.seed_node.variety.name}
                    >
                      <GatsbyImage
                        image={getImage(
                          cartItem.seed_node.images[0]?.image_sharp
                        )}
                        alt={cartItem.seed_node.variety.name}
                      />
                    </Avatar>
                  )) || (
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={`5g - ${cartItem.seed_node.variety.name}`}
                  secondary={cartItem.seed_node.primary_id}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setCartItems([
                        ...cartItems.filter(
                          (ci) =>
                            ci.seed_node.primary_id !==
                            cartItem.seed_node.primary_id
                        ),
                      ]);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemLink>
            ))}
          </List>

          <Divider />

          <List component="nav" aria-label="Cart actions">
            <ListItem
              button
              disabled={cartItems.length === 0}
              onClick={() => setCheckoutDialogOpen(true)}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Checkout" />
            </ListItem>
            <ListItem
              button
              disabled={cartItems.length === 0}
              onClick={() => setCartItems([])}
            >
              <ListItemIcon>
                <RemoveShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Clear" />
            </ListItem>
          </List>

          <CheckoutDialog
            open={checkoutDialogOpen}
            onSubmit={handleSubmit}
            onClose={() => setCheckoutDialogOpen(false)}
          />
        </CartContents>
      </Drawer>
    </CartContainer>
  );
};

export default Cart;
