const config = {
  siteTitle: "Orcas Island Seed Bank",
  siteTitleShort: "OI Seed Bank",
  siteTitleAlt: "Orcas Island Seed Bank",
  siteLogo: "/logos/logo-1024.png",
  siteUrl: process.env.SITE_URL || "https://oiseedbank.org",
  pathPrefix: process.env.PREFIX || "/",
  siteDescription:
    "Orcas Island Seed Bank maintains and shares seeds for locally tested/adapted food crops for the San Juan Islands and neighboring regions",
  siteRss: "/rss.xml",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "YYYY/MM/DD",
  userName: "Curator",
  userEmail: "curator@oiseedbank.org",
  userLocation: "Orcas Island - Washington, USA",
  copyright: "Copyright © 2021. All rights reserved.",
  themeColor: "#c62828",
  backgroundColor: "red",
};

module.exports = config;
