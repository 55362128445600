import React from "react";
import Helmet from "react-helmet";
import TopBar from "../components/TopBar";
import Cart from "../components/Cart";
/* eslint-disable-next-line no-unused-vars */
import * as styles from "./index.module.scss";
import config from "../../data/SiteConfig";
import favicon from "../favicon.png";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";

const MainLayout = ({ children }) => {
  const theme = createTheme();

  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Helmet>
              <meta name="description" content={config.siteDescription} />
              <link rel="icon" href={favicon} />
            </Helmet>
            <TopBar></TopBar>
            <Cart></Cart>
            {children}
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default MainLayout;
