import React from "react";
import { withPrefix } from "gatsby";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import useCartItems from "../utils/oisb-cart-items";
import useCartVisibility from "../utils/oisb-cart-visibility";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import config from "../../data/SiteConfig";
import { StaticImage } from "gatsby-plugin-image";

import { styled } from "@mui/material/styles";

import { Link } from "gatsby";

const StyledBadge = styled(Badge)(
  ({ theme }) => `
  padding: 0 4px;
`
);

const NavTitle = styled(Typography)(
  ({ theme }) => `
    flex-grow: 1;
    color: #111;
    text-indent: -16px;
    padding-left: 8px;

    & a {
      text-decoration: none;
      color: #111;

      &:hover {
          border-bottom: dotted 1px #9055a2;
      },
    },

    & span {
      padding-left: 8px;
      @media (max-width: 583px) {
        display: none;
      }
    }
  `
);

const NavButton = styled(Button)(
  ({ theme }) => `
    color: rgba(0, 0, 0, 0.87);
  `
);

export default function ButtonAppBar() {
  const [cartItems] = useCartItems();
  const [, setCartVisible] = useCartVisibility();

  return (
    <div>
      <AppBar position="fixed" color="default" enableColorOnDark>
        <Toolbar>
          <NavTitle variant="h6">
            <Link to="/">
              <StaticImage
                src="../../static/logos/logo-48.png"
                alt="Orcas Island Seed Bank Logo"
              />
              <span>{config.siteTitle}</span>
            </Link>
          </NavTitle>

          <NavButton href={withPrefix("/")}>Home</NavButton>
          <NavButton href={withPrefix("/about")}>About</NavButton>
          <NavButton href={withPrefix("/seeds")}>Seeds</NavButton>

          <IconButton
            onClick={() => setCartVisible(true)}
            aria-label="Show the cart"
          >
            <StyledBadge badgeContent={cartItems.length} color="error">
              <ShoppingCartIcon />
            </StyledBadge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
